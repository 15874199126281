import React, { useEffect } from 'react'
import './MostrarAvancePreOrden.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { useLocation, useNavigate } from 'react-router-dom'
import { TablaRowSeguimiento } from '../../helpers/TableRow/TableRowSeguimiento'
import { faHourglass, faXmark, faPersonCirclePlus, faCalendarDays, faBinoculars, faHammer, faRepeat, faCheck, faCheckDouble, faGears } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faHourglass, faXmark, faPersonCirclePlus, faCalendarDays, faBinoculars, faHammer, faRepeat, faCheck, faCheckDouble, faGears)

const MostrarOrden = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const data = state
  useEffect(() => {
    if (!data) {
      navigate('/seguimiento')
    }
  }, [navigate, data])

  if (data) {
    return (
      <>
        <div className='contenedor-padre'>
          <div className='card'>
            <div className='title-container'>
              <h2 className='title'>NUMERO DE PRE-ORDEN: {data.orden}</h2>
            </div>
            <div className='table-container'>
              <div className='folio-row'>
                <div className='folio-column'>
                  <h4>
                    NOMBRE: <span className='span-seguimiento'> {data.nombre.toUpperCase()}</span>
                  </h4>
                </div>
                <div className='folio-column column-right'>
                  <h4>
                    ESTADO:
                    <span className='span-seguimiento' style={{ color: data.status !== 'terminado' ? '#019435' : '#00378a', fontWeight: '700' }}> {data.status.toUpperCase()}</span>
                  </h4>
                </div>
              </div>
              <div className='folio-row'>
                <div className='folio-column'>
                  <h4>
                    FOLIO: <span className='span-seguimiento'>{data.folio.toUpperCase()}</span>
                  </h4>
                </div>
                <div className='folio-column'>
                  <h4>
                    FECHA: <span className='span-seguimiento'>{data.fecha}</span>
                  </h4>
                </div>
              </div>
              <div className='folio-row'>
                <div className='folio-column'>
                  <h4>
                    SKU: <span className='span-seguimiento'>{data.sku}</span>
                  </h4>
                </div>
                <div className='folio-column'>
                  <h4>
                    ARTÍCULO: <span className='span-seguimiento'>{data.articulo.toUpperCase()}</span>
                  </h4>
                </div>
              </div>
              <div className='folio-row'>
                <div className='folio-column'>
                  <h4>
                    TIPO: <span className='span-seguimiento'>{data.tipo.toUpperCase()}</span>
                  </h4>
                </div>
                <div className='folio-column'>
                  <h4>
                    PROBLEMA: <span className='span-seguimiento'>{data.comentario}</span>
                  </h4>
                </div>
              </div>
              <div className='folio-row'>
                <div className='folio-column'>
                  <h4 style={{ marginBottom: '3px' }}>
                    INFORMACIÓN DEL TÉCNICO:{' '}
                    <span className='span-seguimiento'>
                      {typeof data.tecnico === 'string' &&
                        data.tecnico.toUpperCase()}
                    </span>
                  </h4>
                  {typeof data.tecnico !== 'string' && (
                    <div>
                      <h5 style={{ marginTop: '0px', marginBottom: '3px' }}>
                        NOMBRE: <span className='span-seguimiento'>
                          {data.tecnico.nombre.toUpperCase()}
                        </span>
                      </h5>
                      <h5 style={{ marginTop: '0px', marginBottom: '3px' }}>
                        EMAIL: <span className='span-seguimiento'>
                          {data.tecnico.email.toUpperCase()}
                        </span>
                      </h5>
                      <h5 style={{ marginTop: '0px', marginBottom: '3px' }}>
                        TELÉFONO: <span className='span-seguimiento'>
                          {data.tecnico.telefono}
                        </span>
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              {data.historia.length > 0
                ? <>
                  <div id="timeline-content">
                    <h2 className='title-historia'>HISTORIAL DE ACTUALIZACIONES</h2>
                    <div className='container-line'>
                      <ul className="timeline">
                        {data.historia.map((historia, index) => (
                            <li
                              key={index}
                              className="event"
                              data-date={historia.fecha_creado}
                            >
                              <FontAwesomeIcon key={index + 1} className='icon-span history-icon' icon={historia.icono}></FontAwesomeIcon>
                              <h3>{historia.progreso}</h3>
                            </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
                : <></>}
              <table className='tabla table-seguimiento'>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <h2 className='header-table-seguimiento'>
                        ACTUALIZACIONES
                      </h2>
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>FECHA</th>
                    <th>COMENTARIO</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof data.comentarios === 'string'
                    ? (
                      <tr>
                        <td
                          colSpan={2}
                          style={{ textAlign: 'center' }}
                        >
                          {data.comentarios.toUpperCase()}
                        </td>
                      </tr>
                      )
                    : (
                        data.comentarios.map((item, index) => (
                        <TablaRowSeguimiento
                          key={index}
                          fecha={item.fecha}
                          comentario={item.comentario}
                        />
                        ))
                      )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default MostrarOrden
